.page:has(.dirty) .boxed,
.page:has(.dirty) .ant-table {
  filter: blur(0.4px);
}
.page:has(.dirty) .recharts-responsive-container {
  visibility: hidden;
}
.page:has(.dirty) .should-save-info {
  display: inline;
}
.should-save-info {
  display: none;
  font-weight: 500;
  text-decoration: underline;
}

.boxed {
  border: 1px solid lightgrey;
  margin-bottom: 10px;
  background-color: white;
}
.boxed:has(.dirty) {
  filter: none !important;
}
.boxed .heading {
  background-color: rgba(0, 0, 0, 0.04);
  margin: 0;
  padding: 10px;
}
.boxed .ant-picker {
  border-radius: 0px;
}

.boxed:has(.dirty) {
  border: 1px solid green;
  position: sticky;
  top: 30px;
  z-index: 1000;
}
.boxed .ant-select-selector {
  border-radius: 0px !important;
  box-shadow: none !important;
  border-color: transparent !important;
}

.x-axis,
.y-axis {
  font-size: 13px;
  color: #5a5a5a;
  text-decoration: underline;
}

/* ANT DESIGN CSS OVERWRITING */

.antd-table-row-red {
  background-color: #f100002c;
}

.antd-table-row-warning {
  background-color: #ffffe0;
  color: black !important;
}

.antd-cell-err {
  position: relative;
  display: inline-block;
  color: red;
  font-weight: 600;
}

.selector1{
  padding: 3px;
  border-radius: 10px;
}
.selector1 .ant-select-selector {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  min-width: 11px;
}

.selector1 .ant-select-selection-item{
  font-size: 24px;
  font-weight: 600 !important;
}

.ant-tag{
  cursor: pointer;
}
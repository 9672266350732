.login-container {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.login-container-inner {
  display: flex;
  width: 100%;
  height: 100vh;
}

.login-form {
  width: 50%;
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
}

.login-form-inner {
  width: 100%;
  max-width: 420px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-wrapper {
  width: 100%;
}

.login-content {
  background-color: #235828;
  width: 50%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 60%;
  overflow: hidden;
}


.login-content-inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.login-logo {
  width: 55px; /* Matched with font size */
  height: auto;
}

.logo-text {
  font-size: 32px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.5px;
}

.login-content p {
  color: white !important;
  font-size: 20px;
  line-height: 1.6;
  opacity: 0.95;
  max-width: 460px;
}

/* Improve input styling */
.auth-input {
  height: 42px !important;
  border-radius: 6px !important;
  border: 1px solid #e2e8f0 !important;
  background-color: white !important;
  transition: all 0.2s ease;
}

.auth-input:hover {
  border-color: #235828 !important;
}

.auth-input:focus {
  border-color: #235828 !important;
  box-shadow: 0 0 0 2px rgba(35, 88, 40, 0.1) !important;
}

/* Form sections */
.form-section {
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.form-section:last-child {
  border-bottom: none;
}

.form-section-title {
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 16px;
}

/* Auth button styling */
.auth-button {
  height: 48px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: 100% !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #235828 !important;
  border-color: #235828 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
}

.auth-button:hover {
  background-color: #1a4420 !important;
  border-color: #1a4420 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(35, 88, 40, 0.2);
}

/* Form labels */
.auth .ant-form-item-label > label {
  font-size: 14px;
  color: #475569 !important;
  font-weight: 500;
}

/* SSO Buttons Styling */
.sso-container {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e0e0e0;
}

.sso-button {
  border-radius: 8px;
  height: 48px;
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.sso-button:hover {
  background-color: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Form Input Styling */
.auth .ant-input,
.auth .ant-input-password {
  height: 48px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.auth .ant-input:focus,
.auth .ant-input-password:focus {
  border-color: #2c5530;
  box-shadow: 0 0 0 2px rgba(44, 85, 48, 0.2);
}

/* Fix for password input alignment */
.auth .ant-input-password {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.auth .ant-input-password .ant-input {
  height: 46px !important; /* Slightly smaller to account for border */
}

.auth .ant-input-password-icon {
  display: flex;
  align-items: center;
  height: 100%;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .login-form {
    padding: 40px;
  }
}

@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-form {
    width: 100%;
    padding: 0;
    position: relative;
  }

  .login-form-inner {
    padding: 16px;
    max-width: 100%;
  }

  .login-content {
    display: none; /* Hide the right content bar on mobile */
  }

  /* Full width form elements */
  .auth-input,
  .auth-button,
  .ant-input-password,
  .ant-select {
    width: 100% !important;
    max-width: 100% !important;
  }

  /* Adjust spacing for mobile */
  .login-title {
    font-size: 24px !important;
    margin-bottom: 16px;
  }

  .login-subtitle {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .auth-form {
    margin: 0;
    width: 100%;
  }

  /* Adjust name fields for mobile */
  .name-fields {
    flex-direction: column;
    gap: 0;
  }

  .name-fields .ant-form-item {
    width: 100%;
  }

  /* Adjust divider spacing */
  .auth-divider {
    margin: 20px 0;
  }

  .auth-divider::before,
  .auth-divider::after {
    width: calc(50% - 60px);
  }

  /* Adjust form footer elements */
  .auth-form-footer {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  /* Adjust SSO buttons */
  .sso-container {
    width: 100%;
  }

  .sso-button {
    width: 100%;
  }

  /* Remove unnecessary padding */
  .login-form-wrapper {
    padding: 16px;
  }
}

/* Additional mobile optimizations for smaller screens */
@media screen and (max-width: 375px) {
  .login-form-inner {
    padding: 12px;
  }

  .login-title {
    font-size: 22px !important;
  }

  .auth-button {
    height: 44px !important;
  }

  .auth-input,
  .ant-input-password,
  .ant-select {
    height: 44px !important;
  }
}

/* Form Label Styling */
.auth .ant-form-item-label > label {
  color: black !important;
  font-weight: 500;
  font-size: 13px !important;
  margin-bottom: 4px;
  height: 20px !important;
}

.auth .ant-form-item-label {
  padding-bottom: 0 !important;
}

.auth .ant-form-item-label > label .anticon-question-circle {
  margin-left: 4px;
  font-size: 14px;
  cursor: help;
  transition: color 0.3s;
}

.auth .ant-form-item-label > label .anticon-question-circle:hover {
  color: #2c5530;
}

/* Form Input Spacing */
.auth .ant-form-item {
  margin-bottom: 12px;
}

/* Tooltip Styling */
.auth .ant-tooltip-inner {
  background-color: #333;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 13px;
  line-height: 1.4;
}

/* Select Dropdown Styling */
.auth .ant-select-dropdown {
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.auth .ant-select-item {
  padding: 8px 12px;
}

.auth .ant-select-item-option-selected {
  background-color: #e8f0e8 !important;
  color: #2c5530;
}

/* Form typography improvements */
.auth .ant-typography {
  color: #1e293b;
}

.auth .ant-checkbox-wrapper {
  color: #64748b;
}

.auth .ant-typography-link {
  color: #4a7e3d !important;
}

.auth .ant-typography-link:hover {
  color: #2c5530 !important;
}

/* Form Styling */
.auth-input.rectangular {
  height: 48px !important;
  border-radius: 4px !important;
  border: 1px solid #e2e8f0 !important;
}

.auth-button.rectangular {
  border-radius: 4px !important;
}

.auth-form-footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.signup-link {
  display: block;
  text-align: center;
  margin-top: 24px;
}

.login-title {
  margin-bottom: 32px;
  text-align: left;
  color: #1a2b3c;
  font-size: 28px !important;
  font-weight: 700 !important;
}

.login-subtitle {
  color: #64748b;
  font-size: 16px;
  margin-bottom: 32px;
  display: block;
}

/* Ensure form inputs take full width on mobile */
.auth .ant-form-item {
  margin-bottom: 16px;
  width: 100%;
}

.auth .ant-input-affix-wrapper {
  width: 100%;
}

/* Sign Up Form Specific Styles */
.name-fields {
  display: flex;
  gap: 16px;
}

.name-fields .auth .ant-form-item {
  flex: 1;
}

.auth-divider {
  position: relative;
  text-align: center;
  margin: 24px 0;
}

.auth-divider::before,
.auth-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 100px);
  height: 1px;
  background-color: #e2e8f0;
}

.auth-divider::before {
  left: 0;
}

.auth-divider::after {
  right: 0;
}

.auth-divider span {
  background-color: white;
  padding: 0 16px;
  color: #64748b;
  font-size: 14px;
}

/* Responsive adjustments for sign up form */
@media screen and (max-width: 768px) {
  .name-fields {
    flex-direction: column;
    gap: 0;
  }

  .name-fields .auth .ant-form-item {
    margin-bottom: 16px;
  }

  .auth-divider::before,
  .auth-divider::after {
    width: calc(50% - 80px);
  }
}

/* Select component styling */
.auth .ant-select.auth-input {
  height: 48px;
}

.auth .ant-select.auth-input .ant-select-selector {
  height: 48px !important;
  padding: 0 11px !important;
  border-radius: 4px !important;
  border: 1px solid #e2e8f0 !important;
}

.auth .ant-select.auth-input .ant-select-selection-search-input {
  height: 46px !important;
}

.auth .ant-select.auth-input .ant-select-selection-item {
  line-height: 46px !important;
}

/* Button Label Styling */
.auth-button {
  margin-top: 8px;
}

.auth .ant-form-item-label > label {
  color: #1e293b;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}

/* Adjust spacing for button form items */
.auth .ant-form-item:last-of-type {
  margin-bottom: 24px;
}

@media only screen and (max-width: 576px) {
  .button-primary-1 {
    font-size: 10px !important;
  }
  .yearSelector-title {
    font-size: 14px !important;
  }
  .ant-select-selection-item {
    font-size: 15px !important;
  }
  .selector1 {
    font-size: 12px !important;
  }
  .offset-mainTitle {
    font-size: 18px !important;
  }
}

/*  (Tablets) */
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .button-primary-1 {
    font-size: 14px !important;
  }

  .yearSelector-title {
    font-size: 16px !important;
  }
  .ant-select-selection-item {
    /* font-size: 20px !important; */
  }
  .selector1 {
    font-size: 14px !important;
  }
}

/*  small screeen laptops */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .button-primary-1 {
    font-size: 14px !important;
  }
  .yearSelector-title {
    font-size: 14px !important;
  }
  .ant-select-selection-item {
    /* font-size: 20px !important; */
  }
  .selector1 {
    font-size: 12px !important;
  }
}

/* Large screen laptops (Desktops) */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
}

/* extra large screen (Large Desktops) */
@media only screen and (min-width: 1201px) {
  .yearSelector-title {
    font-size: 20px !important;
  }
  .ant-select-selection-item {
    /* font-size: 24px !important; */
  }
  .selector1 {
    font-size: 20px !important;
  }
}

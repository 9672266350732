td:has(.site-admin),
.site-admin {
  background: lightgreen !important;
  border-color: limegreen !important;
}

td:has(.company-admin),
.company-admin {
  background: limegreen !important;
  border-color: limegreen !important;
}

td:has(.company),
.company {
  background: #ffd700 !important;
  border-color: #ffd700 !important;
}

td:has(.user),
.user {
  background: lavender !important;
  border-color: lavender !important;
}

td:has(.site),
.site {
  background: pink !important;
  border-color: pink !important;
}

td{
  min-width: 2px !important;
}
.box {
  width: 30px;
  height: 30px;
}

.colorbox{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  width: fit-content;
  border: 1px solid black;
  background-color: white;
}
.colorbox div{
  display: flex;
}
.colorbox .label{
  width: 100px;
}
.targets-container .ant-table-cell{
    padding: 2px !important;
    margin: 0px;
}
 .targets-container .cell{
    padding: 4px 8px !important;
    min-height: 50px !important;
}

.targets-spreadsheet {
  width: 100%;
  font-size: 14px;
}

.targets-spreadsheet td {
  min-width: 120px;
  height: 32px;
  padding: 4px 8px;
}

.targets-spreadsheet td[data-read-only="true"] {
  background-color: #f5f5f5;
  color: #666;
}

.targets-spreadsheet .value-viewer {
  text-align: right;
}
.dash .boxed {
  border: none;
}
.dash .boxed .heading {
  display: none;
}
.dash .type-graph-analysis {
  display: none;
}
.dash .ant-tabs-nav {
  display: none;
}

.text-font-app {
  white-space: pre-wrap !important;
  color: rgb(90, 90, 90) !important;
  font-size: 14px !important;
  letter-spacing: 0.1px;
}

.barrechart {
  position: relative;
}

.options {
  position: absolute;
  display: none;
  top: -27px;
  right: 3px;
  display: block;
  font-size: 23px ;
  z-index: 10;
}
.options:hover{
    zoom: 1.3;
}
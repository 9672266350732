.file-input {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.file-input.dragging {
  border-color: blue;
  background-color: #e6f7ff;
}

.file-preview a {
  color: darkgreen;
}

.file-dragndrop {
  cursor: pointer;
  font-weight: 500;
  color: darkgrey;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-dragndrop input {
  display: none;
}

.file-preview {
  text-align: center;
  margin-bottom: 10px;
}

.error {
  color: red;
}

.file-preview button {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.file-preview button:hover {
  background-color: #e53935;
  color: white !important;
}

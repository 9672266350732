.react-chatbot-kit-chat-container {
  /* width: 100% !important; */
  border: 1px solid darkgreen;
  padding: 3px;
  border-radius: 4px;
  background-color: white;
  min-width: 650px;
}
.react-chatbot-kit-chat-bot-message {
  margin-left: 0 !important;
  width: 100% !important;
  max-width: 600px;
}

.react-chatbot-kit-chat-header {
  background-color: white;
  border-bottom: 1px solid rgb(0, 0, 0);
}


.fullPage .react-chatbot-kit-chat-container{
  max-width: 100% !important;
  min-width: 100% !important;
}

.ready .react-chatbot-kit-chat-btn-send {
  color:rgb(123, 179, 146) !important
}

.notReady .react-chatbot-kit-chat-btn-send {
  background-color:rgb(27, 142, 27) !important
}
.vertical-tabs .ant-tabs-nav-list {
  min-width: 220px;
  max-width: 220px;
  height: 100vh;
  border-radius: 16px;
  padding: 20px 0;
  word-wrap: break-word;
  border: none;
}

.vertical-tabs .ant-tabs-tab-active {
  align-items: center;
  padding: 12px 24px;
  margin-right: 20px !important;
  border-radius: 0 30px 30px 0;
  color: #0a8fdc;
  background-color: rgba(10, 143, 220, 0.1) !important;
}

@media (max-width: 768px) {
  .vertical-tabs .ant-tabs-nav-list {
    /* min-height: 10vh; */
    /* min-width: 100px;
     max-width: 100px; */
    min-height: 10vh;
    max-width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      /* display: none; */
      height: 4px;
      transition: "height 0.3s ease-in-out";
    }
  }
  .vertical-tabs .ant-tabs-tab-active {
    padding: 1px 9px;
    margin-right: 0px !important;
  }
}

.vertical-tabs .ant-tabs-ink-bar-animated {
  display: none;
}
.personalInfo-avatar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vertical-tabs .ant-card {
  min-height: 80vh;
}
.bg-black .ant-tabs-nav-list {
  background-color: #141414 !important;
}
.bg-white .ant-tabs-nav-list {
  background-color: white !important;
  /* margin-bottom: 20px; */
}
@media (max-width: 480px) {
  .ant-tabs-nav-list {
    max-height: 100px;
    overflow-y: auto;
  }
}
  
  
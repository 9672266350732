/* Styles for .ant-table-cell */

.upgrade-table {
  border: 1px solid darkgrey;
  border-radius: 4px;
}

.upgrade-table .ant-table-cell:has(.th) {
  background-color: darkgreen !important;
}

.upgrade-table .ant-table-cell {
  background-color: #d9d9d910;
}

.upgrade-table .ant-table-cell:has(.free) {
  background-color: #e2f0d9;
}

.upgrade-table .ant-table-cell:has(.basic) {
  background-color: #c5e0b4;
}

.upgrade-table .ant-table-cell:has(.premium) {
  background-color: #a9d18e;
}
.upgrade-table .ant-table-cell:has(.pro) {
  background-color: #86be60;
}

.upgrade-table .ant-table-row:has(.free):hover {
  background-color: #e2f0d9;
}

.upgrade-table .ant-table-row:has(.basic):hover {
  background-color: #c5e0b4;
}

.upgrade-table .ant-table-row:has(.premium):hover {
  background-color: #a9d18e;
}

.upgrade-table .ant-table-row:has(.Features):hover {
  background-color: white;
}

.verification-wrapper {
  padding: 10px 20px;
}
.main-verification {
  width: 100%;
}

.flexer {
  display: flex;
  margin-bottom: 5px;
}
.report-data-label {
  width: 30%;
  text-align: right;
  margin-right: 10px;
}

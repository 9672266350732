@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,500&display=swap");
* {
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  z-index: 0;
}
::selection {
  color: white;
  background-color: #593a0e;
}
body {
  margin: 0px;
  padding: 0px;
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(17, 24, 39);
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888888b5;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.disabled-text-show .ant-input {
  color: black;
}

.noinput .ant-input {
  display: none;
}

.antRowShowOnHover {
  display: none !important;
}
.ant-table-cell-row-hover .antRowShowOnHover {
  display: flex !important;
}

b {
  font-weight: 600;
}

.rad .ant-select-selector,
.rad {
  border-top-left-radius: 0 !important;
  flex-grow: 1;
  border-bottom-left-radius: 0 !important;
}

.fake {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 13px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.02);
  min-width: fit-content;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

.table_total {
  font-weight: 600;
}

.anim {
  -webkit-animation: slide-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-4-5 14:34:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-2-hor-top-1
 * ----------------------------------------
/* ----------------------------------------------
 * Generated by Animista on 2023-4-5 14:36:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-br {
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  0% {
    -webkit-transform: translateY(-300px) translateX(-300px);
    transform: translateY(-300px) translateX(-300px);
  }
}
@keyframes slide-br {
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  0% {
    -webkit-transform: translateY(-300px) translateX(-300px);
    transform: translateY(-300px) translateX(-300px);
  }
}

.card-top {
  border: 1px solid hsla(0, 0%, 66%, 0.647);
  font-size: x-large;
  letter-spacing: -1px !important;
  margin: 10px;
  padding: 20px 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
@media (max-width: 599px) {
  .card-top {
    border: none;
    box-shadow: none;
  }
}
.green {
  color: forestgreen;
}
.card-bottom {
  margin: 10px;
  padding: 20px 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.message-box {
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  background-color: darkgreen;
  color: white;
  padding: 10px;
  border: 1px solid darkgreen;
}

.box {
  position: relative;
  --mask: linear-gradient(45deg, #0000 15.55px, #000 0 calc(15.55px + 4px), #0000 0 calc(100% - 15.55px - 4px), #000 0 calc(100% - 15.55px), #0000 0),
    linear-gradient(-45deg, #0000 15.55px, #000 0 calc(15.55px + 4px), #0000 0 calc(100% - 15.55px - 4px), #000 0 calc(100% - 15.55px), #0000 0),
    linear-gradient(90deg, #000 4px, #0000 0 calc(100% - 4px), #000 0) 50%/100% calc(100% - 44px) no-repeat,
    linear-gradient(180deg, #000 4px, #0000 0 calc(100% - 4px), #000 0) 50% / calc(100% - 44px) 100% no-repeat;
}
.box:before {
  content: "";
  position: absolute;
  inset: 0;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.button-primary-1 {
  background: white;
  border: 1px solid green;
  color: green;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: none;
  border-radius: 0px;
}

.button-primary-1:hover {
  background: green !important;
  color: white;
  transition: background 0.3s, color 0.3s;
}
.disabled-div {
  filter: grayscale(100%); /* Apply a full grayscale filter */
  opacity: 0.6; /* Adjust opacity to control the level of grayness */
  pointer-events: none;
}

.no-select {
  pointer-events: none;
  user-select: none;
}

div:has(.ant-dropdown) {
  z-index: 1000 !important;
}

aside {
  z-index: 999 !important;
}

.saf {
  background-color: gold;
  color: black;
  width: 100%;
}
/* .saf:hover {
  border: 1px solid black;
  color: black;
} */
.padding-less .ant-modal-content {
  padding: 0px;
}

.pre-style,
.react-chatbot-kit-chat-bot-message {
  white-space: pre-wrap; /* Preserves whitespace and allows wrapping of text */
  word-wrap: break-word; /* Ensures words break to fit within the container */
  font-family: monospace; /* Uses a monospace font similar to pre */
}

label.ant-radio-button-wrapper::before {
  display: none !important;
}

@media (max-width: 480px) {
  .ant-modal-body {
    overflow-x: scroll;
    min-width: 100%;
  }
  .ant-modal {
    width: 100% !important;
  }
}

.ant-tabs-content-holder {
  border-left: 1px solid transparent !important;
}

/* Skeletonify class */
.skeletonify {
  filter: grayscale(100%) brightness(0.9) contrast(1.2);
  background-color: #e0e0e0;
  overflow: hidden;
  position: relative;
  pointer-events: none; /* Prevents interaction during loading */
}

.skeletonify::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  animation: loading-shimmer 1.5s infinite;
}

/* Animation for shimmer effect */
@keyframes loading-shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.saving-tag {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999999;
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  font-size: 0.9rem;
  border-bottom-left-radius: 5px;
}

.invert {
  filter: invert(1);
}

/*PREVENT chrome auto fill*/
input:-webkit-autofill {
  background-color: white !important; /* Or any other color */
  color: black !important; /* Ensure the text color is readable */
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  background-color: white !important;
}


.heading-logo{
  width: 100px;
}